import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { environment } from '@env/environment';
import { provideTransloco } from '@jsverse/transloco';
import { routes } from './app.routes';
import { AuthModule } from './auth.module';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(AuthModule),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        hideRequiredMarker: false,
        floatLabel: 'auto',
      },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined',
      },
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),

    // withInterceptors([
    //   oktaInterceptor,
    //   //   azureAllowedOrgInterceptor,
    //   //   authInterceptor,
    //   //   spinnerInterceptor,
    //   //   timeoutInterceptor,
    // ]),

    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'es', label: 'Spanish' },
          { id: 'fr', label: 'French' },
          { id: 'ge', label: 'Germany' },
        ],
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        fallbackLang: 'en',
        defaultLang: 'en',
        missingHandler: {
          useFallbackTranslation: false,
        },
        prodMode: environment.production,
      },
    }),
  ],
};
