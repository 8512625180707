import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../core/services/auth.service';
import { MenuItem, Subset } from '../../menu-item';

@Component({
    selector: 'esqep-navbar-menu',
    imports: [NgIf, NgFor, NgClass, NgbCollapse, RouterLink, RouterLinkActive],
    templateUrl: './navbar-menu.component.html',
    styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent {
  private authService: AuthService = inject(AuthService);

  @Input() menuLoading = true;
  @Input() menuData: Array<MenuItem | Subset<MenuItem>> = [];
  @Input() claimNotificationMap = new Map<string, number>();

  subMenu: string;

  get menuReady(): boolean {
    return !this.menuLoading && this.authService.isAuthenticated;
  }

  toggleSubMenu(itemName: string) {
    this.subMenu = this.subMenu == itemName ? null : itemName;
  }
}
