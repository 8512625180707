import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '@env/environment';
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthenticationConfigNames } from './core/authenticationConfigNames';
import { AzureAuthAllowedOrgInterceptor } from './core/interceptors/azure-auth-allowed-org.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { PreAuthGuard } from './guards/pre-auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';

let providers: Array<any> = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  PreAuthGuard,
  AuthGuard,
];
const imports: Array<any> = [];

const authenticationConfigNames: typeof AuthenticationConfigNames =
  AuthenticationConfigNames;

const bootstrapComponents: Array<any> = [];

const authSetup = environment.auth.reduce(
  (a, v) => ({ ...a, [v.authConfigName]: v }),
  {},
);
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: authSetup[authenticationConfigNames.ENTRA].clientId,
      authority: 'https://login.microsoft.com/organizations',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowPlatformBroker: false,
      loggerOptions: {
        loggerCallback: () => {},
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap: new Map([
      [
        'https://graph.microsoft.com/v1.0/me',
        ['https://graph.microsoft.com/.default'],
      ],
      ['http://localhost:4200/*', null],
      [
        environment.serverUrl + '/*',
        [authSetup[authenticationConfigNames.ENTRA].clientId + '/.default'],
      ],
    ]),
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
  };
}
const msalProviders: Array<any> = [
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AzureAuthAllowedOrgInterceptor,
    multi: true,
  },

  MsalService,
  MsalGuard,
  MsalBroadcastService,
];

if (
  authSetup[authenticationConfigNames.ENTRA] &&
  authSetup[authenticationConfigNames.ENTRA].enabled
) {
  imports.push(MsalModule);
  providers = providers.concat(...msalProviders);
  bootstrapComponents.push(MsalRedirectComponent);
}

if (
  authSetup[authenticationConfigNames.OKTA] &&
  authSetup[authenticationConfigNames.OKTA].enabled
) {
  const oktaAuth =
    window.location.search &&
    window.location.search.includes('login=esqep') &&
    authSetup[authenticationConfigNames.OKTA].sqeptechOpenIdIssuer
      ? new OktaAuth({
          issuer:
            authSetup[authenticationConfigNames.OKTA].sqeptechOpenIdIssuer,
          clientId: authSetup[authenticationConfigNames.OKTA].sqeptechClientId,
          redirectUri: window.location.origin + '/login/callback',
        })
      : new OktaAuth({
          issuer: authSetup[authenticationConfigNames.OKTA].openIdIssuer,
          clientId: authSetup[authenticationConfigNames.OKTA].clientId,
          redirectUri: window.location.origin + '/login/callback',
        });
  const moduleConfig: OktaConfig = { oktaAuth };
  imports.push(OktaAuthModule.forRoot(moduleConfig));
}

@NgModule({
  declarations: [],
  imports: imports,
  providers: providers,
  bootstrap: bootstrapComponents,
})
export class AuthModule {}
